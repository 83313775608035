import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// Local imports
import classes from './index.module.scss';
import { baseMapHeight, baseScreenWidth } from './config';

function MainPage() {
	// HOOKS
	const header = useRef<HTMLElement>(null);
	const treatmentsSection = useRef<HTMLElement>(null);
	const contactSection = useRef<HTMLElement>(null);
	const mapOnPage = useRef<HTMLMapElement>(null);
	const location = useLocation();

	// STATE
	const [screenWidth, setScreenWidth] = useState<number>(header.current?.clientWidth || 0);

	// HANDLERS
	const scrollTo = useCallback((refObject: React.RefObject<HTMLElement>) => {
		if (refObject.current) {
			refObject.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	}, []);

	const handleDisplayTreatments = useCallback(() => scrollTo(treatmentsSection), [scrollTo]);

	const handleDisplayContact = useCallback(() => scrollTo(contactSection), [scrollTo]);

	// MEMOS
	const mapHeight = useMemo<number | undefined>(() => {
		if (screenWidth >= 0 && screenWidth < baseScreenWidth) {
			return baseMapHeight;
		} else if (screenWidth >= baseScreenWidth) {
			return (screenWidth / baseScreenWidth) * baseMapHeight;
		} else {
			return undefined;
		}
	}, [screenWidth]);

	// EFFECTS
	// In case of clicking 'Back to main page' button, client always arrives to the top of the main page
	useLayoutEffect(() => window.scrollTo(0, 0), [location]);

	// Listens to screen width
	useLayoutEffect(() => {
		const handleResize = () => {
			setScreenWidth(header.current?.clientWidth || 0);
		};
		const screenClientWidth = header.current?.clientWidth;

		// Sets initial width of Google map iframe (screen width without vertical scroll bar width)
		if (screenClientWidth) setScreenWidth(screenClientWidth);

		window.addEventListener('resize', handleResize);

		return () => {
			// Sets width of Google map iframe after resizing window
			window.removeEventListener('resize', handleResize);
			// Sets position to the top of the page after clicking refresh
			scrollTo(header);
		};
	}, [scrollTo]);

	//RENDERERS
	const intro = useMemo<JSX.Element>(() => {
		return (
			<section className={classes.introSection}>
				<h2>Bemutatkozás</h2>
				<div className={classes.introContainer}>
					<div className={classes.leftCol}>
						<div className={classes.card}>
							<h3>Sipos Boglárka</h3>
							<hr />
							<h4>kozmetikus</h4>
						</div>
					</div>
					<div className={classes.rightCol}>
						<p>
							2003. óta dolgozom kozmetikusként. Az elmúlt években rengeteg professzionális termékcsaládot kipróbáltam, szem előtt tartva a gyógynövények és
							hatóanyagok tisztaságát és fejlődését. Jelenleg Dr. Pál és az Aphrodita Professional készítményeit használom, melyekkel a tini tisztító kezelésektől a
							hidratáló, tápláló, bőrmegújító AHA savas kezeléseken át az anti-aging-ig minden megtalálható az adott bőrnek és évszaknak megfelelően.
						</p>
						<p>Szeretettel várom kedves Vendégeimet az újpalotai kozmetikai szalonomban!</p>
					</div>
				</div>
			</section>
		);
	}, []);

	const listElements = useMemo<(list: string[]) => JSX.Element[]>(() => {
		return (list) =>
			list.map((listElement, idx) => (
				<li key={`listElement-${idx}`} className={classes.listElement}>
					{listElement}
				</li>
			));
	}, []);

	const treatments = useMemo<JSX.Element>(() => {
		return (
			<section className={classes.treatmentsSection} ref={treatmentsSection}>
				<h2>Kezelések</h2>
				<div className={classes.treatmentsContainer}>
					<ul className={classes.leftCol}>{listElements(['Modern gépi kezelések', 'Mikrodermabrázió', 'Thermo', 'Iontoforézis', 'Mezoterápia'])}</ul>
					<ul className={classes.rightCol}>{listElements(['Tisztító- és frissítő kezelések', 'Gyantázások', 'Festések', 'Sminkek', 'Testmasszázsok'])}</ul>
				</div>
			</section>
		);
	}, [listElements]);

	return (
		<div className={classes.mainPage}>
			<header ref={header}>
				<h1>Boglárka Kozmetika</h1>
				<section className={classes.buttonSection}>
					<button onClick={handleDisplayTreatments}>Kezelések</button>
					<Link className={classes.primaryBtn} to="/arak">
						<button>Árak</button>
					</Link>
					<button onClick={handleDisplayContact}>Bejelentkezés</button>
				</section>
			</header>
			<main>
				<>
					{intro}
					{treatments}
					<section className={classes.contactSection} ref={contactSection}>
						<div className={classes.contactImg}></div>
						<div className={classes.contactText}>
							<address>
								<h3 className={classes.odd}>Információ,</h3>
								<h3 className={classes.odd}>bejelentkezés:</h3>
								<a className={classes.even} href="tel:+36204060682">
									+36-20-406-0682
								</a>
							</address>
							<address>
								<h3 className={classes.odd}>Cím:</h3>
								<a
									className={classes.even}
									href="https://www.google.com/maps/search/?api=1&query=Boglárka+Kozmetika%2C+1158+Budapest%2C+Neptun+utca+100"
									target="_blank"
									rel="noreferrer"
								>
									Budapest, XV. Neptun utca 100. fszt.
								</a>
							</address>
						</div>
						<map ref={mapOnPage}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.6072049178374!2d19.136523712074386!3d47.5367743021634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db39c4e4d275%3A0xbca379fd7ef0831d!2sBogl%C3%A1rka%20Kozmetika!5e0!3m2!1shu!2shu!4v1685622008331!5m2!1shu!2shu"
								title="MapOnPage"
								width={screenWidth}
								height={mapHeight}
								style={{ border: 0 }}
								//--loading="lazy"
								loading="eager"
							></iframe>
						</map>
						<button className={classes.toTopBtn} onClick={() => scrollTo(header)}>
							Lap tetejére
						</button>
					</section>
				</>
			</main>
			<footer>
				<section className={classes.impressBtnSection}>
					<Link className={classes.primaryBtn} to="/impresszum">
						<button>Impresszum</button>
					</Link>
				</section>
			</footer>
			<div className={classes.test}></div>
		</div>
	);
}

export default MainPage;
