import { Link, useLocation } from 'react-router-dom';
// Local imports
import classes from './index.module.scss';

function ErrorPage() {
    // HOOKS
    const location = useLocation();

	return (
		<div className={classes.errorPage}>
			<main>
				<h1>A böngészőbe beírt aloldal nem létezik:&nbsp;<span>{location.pathname}</span></h1>
				<Link to="/"><button>Vissza a főoldalra</button></Link>
			</main>
		</div>
	);
}

export default ErrorPage;
