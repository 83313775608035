import { useMemo } from 'react';
import { Link } from 'react-router-dom';
// Extrenal imports
import { Table } from '../PriceListPage/types';
// Local imports
import classes from './index.module.scss';

function ImpressPage() {
	// CONSTANTS
	const impressDataSections = useMemo<Table[]>(
		() => [
			{
				title: 'Vállalkozás adatai',
				rows: [
					['Cégnév:', 'Sipos Boglárka e.v.'],
					['Székhely:', '1158 Budpest, Neptun utca 100, fszt.'],
					['Postai cím:', '1158 Budpest, Késmárk utca 3, I/9.'],
					['Adószám:', '63864425-1-42'],
					['Telefonszám:', '+36204060682'],
				],
			},
			{
				title: 'Tárhelyszolgáltató adatai',
				rows: [
					['Cégnév:', 'Google LLC'],
					['Cím:', '1600 Amphitheatre Parkway,'],
					['', 'Mountain View, CA 94043, USA'],
					['', 'https://firebase.google.com'],
				],
			},
		],

		[],
	);

	// RENDERES
	const impressSections = useMemo<JSX.Element[]>(
		() =>
			impressDataSections.map((sectionData, idx) => (
				<section key={`impressSection-${idx}`} className={classes.impressSection}>
					<h4>{sectionData.title}</h4>
					{sectionData.rows.map((rowData, idx) => (
						<p key={`impressRow-${idx}`}>
							<span className={classes.title}>{rowData?.[0]}</span>
							<span className={classes.data}>{rowData?.[1]}</span>
						</p>
					))}
				</section>
			)),
		[impressDataSections],
	);

	return (
		<div className={classes.impressPage}>
			<main>
				<h1>Impresszum</h1>
				<div className={classes.impressContainer}>
					{impressSections}
				</div>
			</main>
			<footer>
				<Link to="/">
					<button>Vissza a főoldalra</button>
				</Link>
			</footer>
		</div>
	);
}

export default ImpressPage;
