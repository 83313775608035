import { createBrowserRouter } from "react-router-dom";
// External imports
import MainPage from "pages/MainPage";
import PriceListPage from "pages/PriceListPage";
import ErrorPage from "pages/ErrorPage";
import ImpressPage from "pages/ImpressPage";

export const routes = createBrowserRouter([
	{
		path: '/',
		element: <MainPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: 'arak',
		element: <PriceListPage />,
	},
	{
		path: 'impresszum',
		element: <ImpressPage />,
	},
]);